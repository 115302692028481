@import "./../../config/config";

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 2rem 9%;
  z-index: 1000;
  display: flex;
  align-items: center;

  &.active {
    background: $white;
    box-shadow: $box-shadow;
  }

  .logo {
    margin-right: auto;
    font-size: 2.5rem;
    font-weight: bolder;
    color: $black;

    i {
      color: $primary-color;
    }
  }

  .navbar {
    a {
      margin-left: 2rem;
      font-size: 1.7rem;
      color: $black;

      &:hover {
        color: $primary-color;
      }
    }

    #nav-close {
      font-size: 5rem;
      cursor: pointer;
      color: $black;
      display: none;
    }
  }

  .icons a,
  .icons div {
    font-size: 2.5rem;
    margin-left: 2rem;
    cursor: pointer;
    color: $black;

    &:hover {
      color: $primary-color;
    }
  }

  #menu-btn {
    display: none;
  }
}

@media (max-width: 1200px) {
  .header {
    padding: 2rem;
  }
}

@media (max-width: 768px) {
  .header {
    #menu-btn {
      display: inline-block;
    }

    .navbar {
      position: fixed;
      top: 0;
      left: -110%;
      background: $white;
      z-index: 10000;
      width: 35rem;
      height: 100%;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      &.active {
        left: 0;
        box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.8);
      }
      a {
        margin: 1rem 0;
        font-size: 3rem;
      }

      #nav-close {
        display: block;
        position: absolute;
        top: 1rem;
        right: 2rem;
      }
    }
  }
}
