@import "./../../config/config";
@import "./../../config/utilities";

.home {
  padding: 0;

  .box {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-size: cover !important ;
    background-position: center !important;
    padding: 2rem 9%;

    &.second {
      justify-content: flex-start;
    }

    .content {
      width: 50rem;

      span {
        font-size: 4rem;
        color: $black;
      }
      h3 {
        font-size: 6rem;
        color: $primary-color;
        padding-top: 0.5rem;
        text-transform: uppercase;
      }

      p {
        line-height: 2;
        color: $black;
        font-size: 1.5rem;
        padding: 1rem 0;
      }
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    font-size: 2rem;
    color: $primary-color;
  }
}

@media (max-width: 991px) {
  .home .box {
    padding: 2rem;
    justify-content: center;
    background-position: right !important;

    &.second {
      justify-content: center;
      background-position: left !important;
    }

    .content {
      text-align: center;

      span {
        font-size: 3rem;
      }
      h3 {
        font-size: 4rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .home {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }
}
