@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;1,100;1,300&display=swap);
body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}

*{font-family:"Poppins", sans-serif;margin:0;padding:0;box-sizing:border-box;outline:none;border:none;text-decoration:none;text-transform:capitalize;transition:0.2s linear}html{font-size:62.5%;overflow-x:hidden;scroll-behavior:smooth;scroll-padding-top:5rem}html::-webkit-scrollbar{width:1rem}html ::-webkit-scrollbar-track{background-color:#fff}html ::-webkit-scrollbar-thumb{background-color:#10221b}.heading{text-align:center;margin-bottom:2.5rem;font-size:4rem;color:#10221b}section{padding:5rem 9%}@media (max-width: 1200px){section{padding:3rem 2rem}}@media (max-width: 991px){.html{font-size:55%;scroll-padding-top:7rem}}@media (max-width: 450px){html{font-size:50%}}

.about{background:#eee;display:flex;align-items:center;flex-wrap:wrap;grid-gap:2rem;gap:2rem}.about .image{flex:1 1 42rem;padding-right:5rem;padding-bottom:5rem}.about .image img{width:100%;box-shadow:4rem 4rem 0 rgba(0,0,0,0.1)}.about .content{flex:1 1 42rem}.about .content h3{font-size:3rem;color:#219150}.about .content p{font-size:1.5rem;color:#10221b;padding:1rem 0;line-height:2}


.category .box-container{display:grid;grid-template-columns:repeat(auto-fit, minmax(29rem, 1fr));grid-gap:1.5rem;gap:1.5rem}.category .box-container .box{text-align:center;padding:2rem}.category .box-container img{height:20rem;width:20rem;border-radius:50%;margin-bottom:1rem}.category .box-container h3{font-size:2rem;color:#219150}.category .box-container p{font-size:1.5rem;color:#10221b;padding:1rem 0;line-height:2}

.footer{background:linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(/static/media/footer-bg.3d5d6977.jpg) no-repeat;background-size:cover;background-position:center;background-attachment:fixed}.footer .box-container{display:grid;grid-template-columns:repeat(auto-fit, minmax(25rem, 1fr));grid-gap:1.5rem;gap:1.5rem}.footer .box-container .box h3{font-size:2rem;padding:1.5rem;color:#fff}.footer .box-container .box a{display:block;font-size:1.4rem;color:#fff;padding:1rem}.footer .box-container .box a i{color:#219150;padding-right:0.5rem}.footer .box-container .box a:hover{color:#219150}.footer .box-container .box a:hover i{padding-right:2rem}

.btn{margin-top:1rem;display:inline-block;border:0.2rem solid #10221b;color:#10221b;cursor:pointer;background:none;font-size:1.7rem;padding:1rem 3rem}.btn:hover{background:#10221b;color:#fff}.home{padding:0}.home .box{min-height:100vh;display:flex;align-items:center;justify-content:flex-end;background-size:cover !important;background-position:center !important;padding:2rem 9%}.home .box.second{justify-content:flex-start}.home .box .content{width:50rem}.home .box .content span{font-size:4rem;color:#10221b}.home .box .content h3{font-size:6rem;color:#219150;padding-top:0.5rem;text-transform:uppercase}.home .box .content p{line-height:2;color:#10221b;font-size:1.5rem;padding:1rem 0}.home .swiper-button-next,.home .swiper-button-prev{font-size:2rem;color:#219150}@media (max-width: 991px){.home .box{padding:2rem;justify-content:center;background-position:right !important}.home .box.second{justify-content:center;background-position:left !important}.home .box .content{text-align:center}.home .box .content span{font-size:3rem}.home .box .content h3{font-size:4rem}}@media (max-width: 768px){.home .swiper-button-next,.home .swiper-button-prev{display:none}}

.header{position:fixed;top:0;left:0;right:0;padding:2rem 9%;z-index:1000;display:flex;align-items:center}.header.active{background:#fff;box-shadow:0 0.5rem 1rem rgba(0,0,0,0.1)}.header .logo{margin-right:auto;font-size:2.5rem;font-weight:bolder;color:#10221b}.header .logo i{color:#219150}.header .navbar a{margin-left:2rem;font-size:1.7rem;color:#10221b}.header .navbar a:hover{color:#219150}.header .navbar #nav-close{font-size:5rem;cursor:pointer;color:#10221b;display:none}.header .icons a,.header .icons div{font-size:2.5rem;margin-left:2rem;cursor:pointer;color:#10221b}.header .icons a:hover,.header .icons div:hover{color:#219150}.header #menu-btn{display:none}@media (max-width: 1200px){.header{padding:2rem}}@media (max-width: 768px){.header #menu-btn{display:inline-block}.header .navbar{position:fixed;top:0;left:-110%;background:#fff;z-index:10000;width:35rem;height:100%;display:flex;flex-flow:column;align-items:center;justify-content:center}.header .navbar.active{left:0;box-shadow:0 0 0 100vw rgba(0,0,0,0.8)}.header .navbar a{margin:1rem 0;font-size:3rem}.header .navbar #nav-close{display:block;position:absolute;top:1rem;right:2rem}}

.newsletter{background:#eee}.newsletter .content{max-width:70rem;margin:1rem auto;text-align:center}.newsletter .content p{font-size:1.5rem;line-height:2;color:#10221b}.newsletter .content form{margin-top:2rem;background:#fff;border-radius:5rem;border:0.2rem solid #10221b;padding:0.7rem;display:flex}.newsletter .content form .email{width:100%;background:none;text-transform:none;font-size:1.7rem;color:#10221b;padding:0 1.3rem}.newsletter .content form .btn{margin-top:0;border-radius:5rem;background:#10221b;color:#fff}.newsletter .content form .btn:hover{background:none;color:#10221b}

.packages{background:#eee}.packages .box-container{display:grid;grid-template-columns:repeat(auto-fit, minmax(32rem, 1fr));grid-gap:1.5rem;gap:1.5rem}.packages .box-container .box{text-align:center;background:#fff;border:0.2rem solid #10221b;box-shadow:0 0.5rem 1rem rgba(0,0,0,0.1);border-radius:0.5rem}.packages .box-container .box:hover{background:#10221b}.packages .box-container .box:hover .content>*{color:#fff}.packages .box-container .box:hover .btn{border-color:#fff}.packages .box-container .box:hover .btn:hover{background:#fff;color:#10221b}.packages .box-container .box .image{height:25rem;overflow:hidden;padding:2rem;padding-bottom:0;border-radius:0.5rem}.packages .box-container .box .image img{height:100%;width:100%;object-fit:cover;border-radius:0.5rem}.packages .box-container .box .content{padding:2rem}.packages .box-container .box .content h3{font-size:2rem;color:#10221b}.packages .box-container .box .content p{font-size:1.5rem;color:#10221b;padding:1rem 0;line-height:2}.packages .box-container .box .content .price{font-size:2rem;color:#219150}


.reviews .wrapper{display:flex;justify-content:space-around}.reviews .slide{padding:2rem}.reviews .slide .text{padding:2rem;font-size:1.6rem;font-style:italic;background:#eee;border-radius:0.5rem;color:#10221b;line-height:2;position:relative;z-index:0;margin-bottom:3rem}.reviews .slide .text::before{content:"";position:absolute;bottom:-1.5rem;left:0.7rem;height:3rem;width:3rem;background:#eee;transform:rotate(45deg)}.reviews .slide .user{display:flex;align-items:center;grid-gap:1rem;gap:1rem}.reviews .slide .user img{height:7rem;width:7rem;border-radius:50%}.reviews .slide .user h3{font-size:2rem;color:#10221b}.reviews .slide .user span{color:#219150;font-size:1.5rem}@media (max-width: 450px){.reviews .wrapper{flex-direction:column}}

.search-form{position:fixed;top:0;left:0;height:100%;width:100%;background:rgba(0,0,0,0.8);display:flex;align-items:center;justify-content:center;z-index:10000;transform:translateY(-110%)}.search-form.active{transform:translateY(0%)}.search-form #close-search{position:absolute;top:1.5rem;right:2.5rem;cursor:pointer;color:#fff;font-size:6rem}.search-form:hover{color:#219150}.search-form form{width:70rem;margin:0 2rem;padding-bottom:2rem;border-bottom:0.2rem solid #fff;display:flex;align-items:center}.search-form form input{width:100%;font-size:1.7rem;color:#fff;text-transform:none;background:none;padding-right:2rem}.search-form form input::-webkit-input-placeholder{color:#aaa}.search-form form input:-ms-input-placeholder{color:#aaa}.search-form form input::placeholder{color:#aaa}.search-form form label{font-size:3rem;cursor:pointer;color:#fff}.search-form form label:hover{color:#219150}

.services{background:#eee}.services .box-container{display:grid;grid-template-columns:repeat(auto-fit, minmax(31rem, 1fr));grid-gap:1.5rem;gap:1.5rem}.services .box-container .box{text-align:center;padding:2rem;border:0.2rem solid #10221b;border-radius:0.5rem;background:#fff}.services .box-container .box:hover{background:#10221b}.services .box-container .box:hover img{-webkit-filter:invert(1);filter:invert(1)}.services .box-container .box:hover p{color:#fff}.services .box-container .box:hover .btn{border-color:#fff;color:#fff}.services .box-container .box:hover .btn:hover{background:#fff;color:#10221b}.services .box-container .box img{height:10rem;margin-bottom:1}.services .box-container .box h3{font-size:2rem;color:#219150}.services .box-container .box p{font-size:1.5rem;color:#10221b;padding:1rem 0;line-height:2}

.stars i{font-size:1.7rem;color:#10221b}


.shop .slide{border:0.2rem solid #10221b}.shop .slide:hover .image .icons{transform:translateY(0rem)}.shop .slide .image{position:relative;overflow:hidden;height:30rem;width:100%}.shop .slide .image img{height:100%;width:100%;object-fit:cover}.shop .slide .image .icons{width:100%;position:absolute;bottom:2rem;left:0;text-align:center;z-index:10;transform:translateY(7rem)}.shop .slide .image .icons a{height:4.5rem;width:4.5rem;line-height:4.5rem;font-size:1.7rem;background:#10221b;color:#fff;margin:0 0.2rem}.shop .slide .image .icons a:hover{background:#219150}.shop .slide .content{padding:1rem 0;text-align:center}.shop .slide .content h3{font-size:2rem;color:#10221b}.shop .slide .content .price{padding:1rem 0;padding-top:0.5rem;font-size:2.5rem;color:#219150}.shop .swiper-container{display:flex;align-items:center;justify-content:center}

